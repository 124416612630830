<template>
    <div class="solutions__card">
        <div class="solutions__card-title">
            <h3 class="block-title product-card__title">{{ props.solution.title }}</h3>
        </div>
        <div class="solutions__card-img">
            <div v-bind:id="props.solution.id"></div>
        </div>
        <div class="solutions__card-text">
            <div class="description-text">
                <p>{{ props.solution.descr_text }}</p>
                <ul class="solutions_card-list">
                    <li class="solutions_card-list-item" v-if="props.solution.gost" v-for="item in props.solution.gost">{{
                        item }}
                    </li>
                </ul>
                <h4 class="solutions__card-text-bold">{{ props.solution.bold }}</h4>
                <ul class="solutions_card-list">
                    <li class="solutions_card-list-item" v-for="item in props.solution.solutions_part_list">{{ item }}</li>
                </ul>
            </div>
        </div>

        <div class="buttons_block">
            <div class="solutions__card-presentation-btn" v-if="props.solution.presentation">
                <div class="solutions__card-presentation-img"></div>
                <a :href="href">Скачать презентацию</a>
            </div>
            <button class="solutions_card-button" @click="button_click">{{ props.solution.btn_text }}</button>
        </div>
    </div>
</template>
<script setup>

const props = defineProps({
    solution: Object
});

const href = `${props.solution?.presentation}`
function button_click() {
    window.location.href = `${window.location.origin}/${props.solution.page}`
}
</script>
<style lang="scss" scoped>

.solutions {
    &__card {
        padding: rem(20) rem(20) rem(32) rem(20);
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #FBFBFB 0%, #F8F8F8 100%);


        @include desktop {
            max-width: rem(360);
            min-height: rem(827);
            min-width: rem(300);
        }

        @include mobile {
            max-width: rem(480);
            min-height: rem(907);
        }
    }

    &__card-title {
        margin-bottom: 24px;
        text-align: center;
    }


    &__card-img {
        width: rem(300);
        height: rem(195);
        margin: 0 auto 24px;
    }

    &__card-text {

        text-align: left;

        .description-text {
            min-height: rem(160);

            &>* {
                margin-bottom: 24px;
            }

            li {
                list-style-type: disc;
                margin-left: 25px;
            }
        }


    }

    &__card-text-bold {
        margin-bottom: rem(25);
    }

    &__card-presentation-btn {
        width: 80%;
        min-height: rem(50);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0D1BF5;
        cursor: default;
    }

    &__card-presentation-img {
        background-image: url('~@/assets/img_solutions_home/presentation.svg');
        background-size: cover;
        min-width: rem(50);
        min-height: rem(50);
    }

    #users-img {
        @include solutionsImg {
            background-image: url('~@/assets/img_solutions_home/users.svg');
        }
    }

    #docs-img {
        @include solutionsImg {
            background-image: url('~@/assets/img_solutions_home/docs.svg');
        }
    }

    #biometric-img {
        @include solutionsImg {
            background-image: url('~@/assets/img_solutions_home/biometric.svg');
        }
    }

    .buttons_block {
        margin-top: auto;
        display: flex;
        flex-direction: column;


        .solutions_card-button {
            width: 80%;
            min-height: rem(50);
            border: 2px solid #0D1BF5;
            margin: 0 auto;
            font-family: Comfortaa;
            text-transform: uppercase;
            color: #0D1BF5;

            &:hover {
                background-color: #6FAEE9;
            }
        }
    }

}
</style>


