<template>
    <div class="page">
        <Header :img_path="img" :title_text="title" :descr_text="descr_text" :btn_text="btn_text" @openModal="openModal" />
        <About />
        <Technologies />
        <Products />
        <Solutions />
        <Footer />
        <Modal v-if="showModal" :typeModal="modalType" @closeModal="close"/>
    </div>
</template>

<script setup>
import { ref } from "vue";
import Header from '../../common_components/Header.vue';
import About from './components/About.vue';
import main_page_img from '../../assets/img_home/header__image_mobile@1x.png';
import Technologies from './components/Technologies.vue';
import Products from './components/Products.vue';
import Solutions from './components/Solutions.vue';
import Footer from '../../common_components/Footer.vue';
import Modal from "@/common_components/Modal.vue";

const img = main_page_img,
    title = 'Разработка и производство защищенных сВТ',
    descr_text = 'Разработка программного и аппаратного обеспечения, соответствующего требованиям регуляторов(ФСБ России, ФСТЭК России, МО РФ).',
    btn_text = 'Оставить запрос'


const modalType = ref('product_cost');
const showModal = ref(false);

function close() {
    showModal.value = false
}

function openModal(type) {
    modalType.value = type;
    showModal.value = true
}

</script>

<style lang="scss" scoped>
// @import '@/assets/scss/main.scss';
</style>
