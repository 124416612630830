<template>
    <section class="products" id="products">
        <div class="container">
            <h2 class="section-title products__title">Наши продукты</h2>
            <p class="products-description" v-if="!isHidden">Avida-Smart — ведущий поставщик промышленных компьютеров и серверов, предназначенных для поддержки вашего бизнеса. Получите максимальную отдачу от своего бизнеса с нашими надежными и мощными решениями.</p>
            <div class="products-container ">
                <div class="products-container-part">
                    <ProductCard v-for="product in products_list" key="product.id" :product="product">
                    </ProductCard>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
import ProductCard from './ProductCard.vue';
import { getProducts } from '@/service/fetch.service.js';
// import  products_list  from '@/assets/products';
import { computed, ref } from 'vue';
const products_list =  ref()

getProducts().then(d => products_list.value = d)
const isHidden = computed(() => window.matchMedia("(min-width: 745px)").matches ? false : true)
</script>
<style scoped lang="scss">
.products {
    @include desktop {
        padding-top: rem(120);
        margin-bottom: rem(120);
    }

    @include mobile {
        margin-bottom: rem(86);
    }



    &__title {
        font-family: Montserrat;
        font-weight: 700;

        @include mobile {
            margin-top: rem(80);
            margin-bottom: rem(30);
        }

        @include desktop {
            text-align: center;
            margin-bottom: rem(24);
        }
    }
}

.products-description {
    @include desktop {
        max-width: rem(840);
        margin: 0 auto;
        text-align: center;
        margin-bottom: rem(55);
    }
}

.products-container {
    width: 100%;

    // @media (max-width:990px) {
    //     padding: 0 rem(1);

    // }
}

.products-container-part {
    @include desktop {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    @media (max-width:990px) {
        row-gap: 20px;

    }

    width: 100%;

    @include mobile {
        grid-template-columns: 1;
        grid-template-rows: auto;
        width: 100%
    }

}

.slider {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &__list {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        transform: translate3d(0px, 0, 0);
    }

    &__item {
        flex-shrink: 0;
        position: relative;
        transition-property: transform;
    }

    &__navigation {
        @include mobile {
            display: none;
        }


    }

    &__button {
        @include desktop {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: rem(48);
            height: rem(48);
            z-index: 10;
            color: #2B5D82;
            opacity: 0.35;

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

            &:hover {
                opacity: 1;
                color: #A8D1F0;
            }

            &_disabled {
                opacity: 1;
                color: #EEEEEE;
                cursor: auto;
                pointer-events: none;
            }

            &_prev {
                right: auto;
                left: 0;

                @media (max-width: 745) {
                    left: rem(48);
                }
            }

            &_next {
                left: auto;
                right: 0;

                @media (max-width: 745px) {
                    right: rem(48);
                }
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile {
            margin-top: rem(40);
        }

        @include desktop {
            margin-top: rem(12);
        }

        &-bullet {
            width: rem(12);
            height: rem(12);
            border: 2px solid #0D1BF5;

            &:not(:first-child) {
                margin-left: rem(14);
            }

            &_active {
                width: rem(16);
                height: rem(16);
                background: #0D1BF5;
                border: 3px solid #0D1BF5;
                box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }
}
</style>