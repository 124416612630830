<template>
    <section class="solutions" id="solutions">
        <div class="solutions-container">

            <h2 class="section-title solutions__title">Программные решения</h2>
            <p class="solutions__text">{{ text_solutions_description }}</p>
            <!-- <div class="solutions__cards-wrapper" v-if="solutinons_visibility">
                <Solution v-for="solution in solutions_list" key="solution.id" :solution="solution" />
            </div> -->


            <!-- <div v-if="!solutinons_visibility"> -->
            <Carousel v-if="isMobile" :itemsToShow="itemsToShow" :wrapAround="isMobile" :mouseDrag="isMobile"
                :touchDrag="isMobile">
                <Slide v-for="solution in solutions_list" key="solution.id">
                    <Solution :solution="solution"></Solution>
                </Slide>
                <!-- <template #addons>
                    <Pagination />
                </template> -->
            </Carousel>
            <!-- </div> -->
            <div v-else class="solutions__cards-wrapper">
                <div v-for="solution in solutions_list" key="solution.id">
                    <Solution :solution="solution"></Solution>
                </div>
            </div>
        </div>

    </section>
</template>
<script setup>
import Solution from './Solution.vue';
import { solutions_list, text_solutions_description } from '@/assets/solutions_info.js';
import { ref, onMounted, onUnmounted, computed } from 'vue';
// import { Swiper, SwiperSlide, Pagination, } from 'swiper/vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css'

const isMobile = ref(window.innerWidth < 745 ? true : false)
const changeSolutionsLayout = () => {
    console.log(window.innerWidth);
    isMobile.value = window.innerWidth < 745 ? true : false
}
onMounted(() => window.addEventListener("resize", changeSolutionsLayout));
onUnmounted(() => window.removeEventListener("resize", changeSolutionsLayout));

const itemsToShow = computed(() => window.matchMedia("(min-width: 745px)").matches ? 3 : 1.6)


</script>
<style lang="scss" scoped>
.solutions-container {
    position: relative;
    margin: 0 auto;



    @include mobile {
        padding: 0;
        width: 100%;
        min-width: rem(300);
    }

    @include desktop {
        padding: 0 rem(48);
        width: rem(1120);
    }
}
.solutions {

    &__title {
        font-family: 'Montserrat';

        @include mobile {
            margin-bottom: rem(28);
            font-size: rem(24);
            text-align: center;
        }

        @include desktop {
            margin-bottom: rem(24);
            text-align: center;
        }
    }

    &__text {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: rem(40);
    }

    &__cards-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @include desktop {
            margin-bottom: rem(126);
        }
    }

}

.carousel__slide {
    padding: -15px;
}

.carousel__viewport {
    perspective: 2000px;
}

.carousel__track {
    transform-style: preserve-3d;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 0.9;
    transform: rotateY(0) scale(0.9);
}

.carousel__slide--active~.carousel__slide {
    transform: rotateY(0) scale(0.9);
}

.carousel__slide--prev {
    opacity: 1;
    transform: rotateY(0) scale(0.9);
}

.carousel__slide--next {
    opacity: 1;
    transform: rotateY(0) scale(0.9);
}

.carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1);
}
</style>