<template>
    <div class="product-card" @click="click">
        <img :src="props.product.img" alt="title" class="product-card__image">
        <div class="product-card__content">
            <div class="product-card__title">
                <h3 class="block-title product-card__title">{{ props.product.title }}</h3>
            </div>
            <div class="product-card__content">
                <p>{{ props.product.description }}</p>
            </div>
        </div>
    </div>
</template>
<script setup>

const props = defineProps({
    product: Object
});

function click(){
    window.location.href = `${window.location.origin}/product.html?id=${props.product.id}`
}

</script>
<style scoped lang="scss">
.product-card {
    @include mobile {
        display: flex;
        flex-direction: row;
    }

    @include desktop {
        display: flex;
        flex: 1;
        height: rem(134);
        margin-bottom: rem(28);
        cursor: pointer;

        @media (max-width:990px) {
            height: rem(150);
        }
    }

    &__image {
        @include mobile {
            width: 30%;
            //  height: rem(352);
            margin: 0 auto rem(24);
        }

        @media (max-width:990px) {
            width: 50%;
        }

        img {
            height: 100%;
            padding-right: rem(14);
        }
    }

    &__content {
        @include mobile {
            width: 90%
        }

        @include desktop {
            width: 100%;
        }
    }

    &__title {
        font-family: Comfortaa;
        margin-bottom: rem(20);
        font-weight: 600;
    }

    p {
        @include main-text;

        @include mobile {
            -webkit-line-clamp: 3;
            /* Число отображаемых строк */
            display: -webkit-box;
            /* Включаем флексбоксы */
            -webkit-box-orient: vertical;
            /* Вертикальная ориентация */
            overflow: hidden;
        }
    }


}
</style>